.About{
    width: 100%;
    height: fit-content;
    background-color: #ffffff;
    padding: 0px 40px 30px 40px;
    gap: 30px;
}
.About h1{
    color: #4a4a4a;
    width: fit-content;
}
.about-text-img {
    width: 100%;
    gap: 20px;
    overflow: hidden;
}
.short-bio{
    width: 70%;
    gap: 22px;
    justify-content: center;
}
.short-bio h2{
    color:#4a4a4a;
}
.small-res{
    justify-content: space-between;
}
.small-res ul {
    gap: 30px;
}
.small-res ul li{
    font-size: 18px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    font-family: quick;
}
@media only screen and (max-width: 1000px) {
    .small-res{
        flex-direction: column;
        gap: 40px;
    }
    .About{
        padding: 10px;
        width: 80%;
    }
}
@media only screen and (max-width: 600px) {
    .short-bio{
        width: 100% !important;
    }

}
@media only screen and (max-width: 500px) {
    .short-bio{
        width: 100% !important;
    }
    .small-res{
        flex-direction: column !important;
    }

}