.background-pic {
    width: 80%;
    height: 100%;
    position: absolute;
    right: 0px;
    background-image: url(../../images/somePic1.jpg); 
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    filter: blur(0.4px);
    z-index: 1;
    gap: 300px;
}
.texts{
    filter: blur(0.7px);
    margin: 160px auto;
    width: fit-content;
}
.animation-text ,.name-text{
    font-size: 60px;
    text-align: center !important;
    color: #f1efef;
    z-index: 1;
    font-family: quick;
}
/* @media only screen and (max-width: 990px) {
    .background-pic{
        width: 100%;
        z-index: 0;
        background-position: center;
    }
    .animation-text{
        display: none;
    }
} */
@media only screen and (max-width:1000px){
    .background-pic{
        background-image: none;
        gap: 0px;
        width: 100%;
        z-index: 0;
        filter: none !important;
        padding: 0px;
    }
    .name-text{
        margin-top: -140px;
        width: 400px;
        text-align: center;
    }
    .animation-text ,.name-text{
        font-size: 60px;
        text-align: center !important;
        color: #1f29e2;
        z-index: 1;
        font-family: quick;
    }
    .animation-text{
        display: none;
    }
    
}