*{
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-decoration: none;
  box-sizing: border-box;
}
body{
  color: #4a4a4a;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}
/* scroll style */
html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #515152;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}
/* Fonts  */
@font-face {
  font-family: "montseratti";
  src: url(./fonts/MontserratAlternates-LightItalic.ttf);
}

@font-face {
  font-family: "quick";
  src: url(./fonts/newFont/Quicksand-VariableFont_wght.ttf);
}

@font-face {
  font-family: "Bigmontseratti";
  src: url(./fonts/MontserratAlternates-Light.ttf);
}

@font-face {
  font-family: "lato";
  src: url(./fonts/lato/Lato-Light.ttf);
}
h1,h2,h3{
  font-weight: 400;
}
h1 {
  font-size: 32px;
  font-family: "lato";
}
h2 {
  font-size: 28px;
  font-family: "lato";
}

h3 {
  font-size: 22px;
  font-family: "lato";
}
p,
label {
  font-size: 18px;
  font-family: "quick";
}

li {
  font-family: "lato";
}

a {
  font-family: "Bigmontseratti";
  color: white;
}
.header{
  width: 20% !important;
}
.MainStyle{
  display: flex;
  flex-direction: row;
}
.Backgrounds{
  width: 80%;
}
.burger-menu{
  width: 50px;
  height: 50px;
  background-color: #515152;
  z-index: 11;
  position: absolute;
  top: 10px;
  left: 10px;
}